import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

const HeaderImage = ({ post }) => {
    const imageData = post.frontmatter.featuredImage.childImageSharp.gatsbyImageData

    return (
        <section className="relative z-0 max-w-screen-lg mx-auto overflow-hidden lg:rounded-lg aspect-video">
            <GatsbyImage
                image={imageData}
                alt={"Thumbnail"}
                placeholder="blurred"
                layout="fullWidth"
                objectFit="cover"
                objectPosition="50% 50%"
                className="transition-all"
            />
        </section>
    );
}

export default HeaderImage;