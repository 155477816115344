import * as React from "react"

export default function ComponentCtaCard({ author }) {
  return (
    <div className="px-6 py-6 my-8 text-gray-500 rounded-lg bg-[#e5e5ef] dark:bg-neutral-800 dark:text-yellow-500">
      <div className="flex flex-wrap items-start sm:space-x-6 sm:flex-nowrap hidden md:flex">
        The links to all components can be found on the right side of the
        article. 👉
      </div>
      <div className="flex flex-wrap items-start sm:space-x-6 sm:flex-nowrap md:hidden">
        The links to all components can be found at the top of the article. ☝️
      </div>
    </div>
  )
}
