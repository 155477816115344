import { cx } from "#utils/all"
import { HeartIcon } from "@heroicons/react/24/solid"
import { getDatabase, increment, onValue, ref, set } from "firebase/database"
import app from "gatsby-plugin-firebase-v9.0"
import React, { useEffect, useState } from "react"

const ClapsButton = ({ slug, className }) => {
  const [clapsCounter, setClapsCounter] = useState(0)
  const database = getDatabase(app)
  // const query = `claps${slug}clapCount`;
  const query = `posts${slug}likes`

  useEffect(() => {
    const dataRef = ref(database, query)
    onValue(dataRef, snapshot => {
      const data = snapshot.val()

      if (!data) {
        set(ref(database, query), 0)
      }

      setClapsCounter(data)

      return () => {
        setClapsCounter({}) // This worked for me
      }
    })
  }, [database, query])

  const incrementClapsCounter = () => {
    setClapsCounter(prevState => prevState + 1)
    set(ref(database, query), increment(1))
  }

  return (
    <button
      type="button"
      onClick={incrementClapsCounter}
      className={cx(
        "group m-0 text-gray-900 dark:text-white focus:outline-none font-medium rounded-lg text-xs px-2 py-2.5 text-center mb-2 md:hover:scale-125 transition-all",
        className
      )}
    >
      <HeartIcon className="w-6 h-8 m-0 text-red-500 group-hover:animate-wiggle"></HeartIcon>
      {clapsCounter}
    </button>
  )
}

export default ClapsButton
