import TagsDatabase from "#constants/TagsDatabase"
import { cx } from "#utils/all"
import { incrementCounter } from "#utils/firebase"
import { useLocation } from "@gatsbyjs/reach-router"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"

const PostNavigation = ({ post, previous, next }) => {
  const location = useLocation()

  function incrementNavigationClick(direction) {
    incrementCounter(
      location,
      `posts${post.fields.slug}navigation/${direction}`
    )
  }
  return (
    <div
      className={cx(
        "grid gap-4 mt-4 md:mb-24 md:mt-24 lg:gap-10",
        (next && !previous) || (!next && previous)
          ? "md:grid-cols-1 xl:grid-cols-1"
          : "md:grid-cols-2 xl:grid-cols-2"
      )}
    >
      {previous ? (
        <div className="group">
          <div
            className={cx(
              "relative transition-all rounded-lg overflow-hidden group-hover:scale-105",
              true ? "aspect-video" : "aspect-square"
            )}
          >
            <Link
              to={`/post${previous.fields.slug}`}
              onClick={() => incrementNavigationClick("previous")}
            >
              <div className="transition-all">
                <GatsbyImage
                  image={
                    previous.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  }
                  alt={"Thumbnail"}
                  placeholder="blur"
                  sizes="80vw"
                  layout="fullWidth"
                  objectfit="cover"
                  className="transition-all"
                />
                <h3 className="absolute z-40 top-3 left-3 text-xs transition-all bg-slate-100 dark:bg-black dark:bg-opacity-50 group-hover:bg-opacity-100 px-3 py-2 rounded-lg m-0">
                  <ArrowLeftIcon className="w-3 h-3 mb-[2px] dark:text-gray-300 inline"></ArrowLeftIcon>{" "}
                  Previous post
                </h3>
              </div>
            </Link>
          </div>
          <Link
            to={`/post${previous.fields.slug}`}
            className="no-underline"
            onClick={() => incrementNavigationClick("previous")}
          >
            <h3
              className={`mt-3 text-sm font-bold tracking-normal text-brand-primary dark:text-white g-brand-secondary/20 w-max bg-gradient-to-r ${
                TagsDatabase[previous.frontmatter.tags[0]].underlineClass
              } ${
                TagsDatabase[previous.frontmatter.tags[0]].underlineClassDark
              } bg-[length:0px_10px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_10px]`}
            >
              {previous.frontmatter.title}
            </h3>
          </Link>
        </div>
      ) : (
        <div></div>
      )}
      {next && (
        <div className="group">
          <div
            className={cx(
              "relative transition-all rounded-lg overflow-hidden group-hover:scale-105",
              true ? "aspect-video" : "aspect-square"
            )}
          >
            <Link
              to={`/post${next.fields.slug}`}
              onClick={() => incrementNavigationClick("next")}
            >
              <div className=" transition-all">
                <GatsbyImage
                  image={
                    next.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  }
                  alt={"Thumbnail"}
                  placeholder="blur"
                  sizes="80vw"
                  layout="fullWidth"
                  objectfit="cover"
                  className="transition-all"
                />
                <h3 className="absolute z-40 top-3 right-3 text-xs transition-all bg-slate-100 dark:bg-black dark:bg-opacity-50 group-hover:bg-opacity-100 px-3 py-2 rounded-lg m-0">
                  Next post{" "}
                  <ArrowRightIcon className="w-3 h-3 mb-[2px] dark:text-gray-300 inline"></ArrowRightIcon>
                </h3>
              </div>
            </Link>
          </div>
          <Link
            to={`/post${next.fields.slug}`}
            className="no-underline"
            onClick={() => incrementNavigationClick("next")}
          >
            <h3
              className={`mt-3 text-sm font-bold tracking-normal text-brand-primary dark:text-white g-brand-secondary/20 w-max bg-gradient-to-r ${
                TagsDatabase[next.frontmatter.tags[0]].underlineClass
              } ${
                TagsDatabase[next.frontmatter.tags[0]].underlineClassDark
              } bg-[length:0px_10px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_10px]`}
            >
              {next.frontmatter.title}
            </h3>
          </Link>
        </div>
      )}
    </div>
  )
}

export default PostNavigation
