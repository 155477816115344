import { format, parseISO } from "date-fns";
import * as React from 'react';
import CategoryLabel from '../category';

const Header = ({ post }) => {
    const readingTime = post.fields.readingTime.text

    return (
        <section className="max-w-screen-md mx-auto">
            <div className="text-center flex justify-center">
                <CategoryLabel categories={post.frontmatter.tags} />
            </div>

            <h1 className="mt-2 mb-3 text-3xl font-semibold tracking-tight text-center lg:leading-snug text-brand-primary lg:text-3xl dark:text-white">
                {post.frontmatter.title}
            </h1>

            <div className="flex justify-center mt-3 space-x-3 text-gray-500 ">
                <div className="flex items-center gap-3">
                    <div className="relative flex-shrink-0 w-10 h-10">
                        <img
                            src="/profile.jpeg"
                            alt="Ivan Lesar"
                            className="rounded-full"
                        />
                    </div>
                    <div className="text-sm">
                        <p className="text-gray-800 dark:text-gray-400">
                            Ivan Lesar
                        </p>
                        <div className="flex items-center space-x-2">
                            <time
                                className="text-gray-500 dark:text-gray-400"
                                dateTime={
                                    post.frontmatter.date
                                }>
                                {format(
                                    parseISO(
                                        post.frontmatter.date
                                    ),
                                    "MMMM dd, yyyy"
                                )}
                            </time>
                            <span className="text-sm">
                                · {readingTime || "5"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;