import { LinkIcon } from "@heroicons/react/24/solid";
import { slug } from "github-slugger";
import * as React from 'react';
import { useEffect } from "react";

const TableOfContents = ({ headings }) => {

    useEffect(() => {
        document.querySelectorAll('.table-of-contents a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    })

    return (
        <div className="bg-[#e5e5ef] dark:bg-neutral-800 px-6 py-8 mb-6 rounded-lg table-of-contents">
            <h2 className="mb-4 text-lg font-semibold text-gray-900 dark:text-white m-0">Table of contents</h2>

            <ul className="m-0 space-y-2 text-left text-gray-500 dark:text-gray-400 pl-4">
                {headings && headings.map(heading => (
                    <li className="flex items-center space-x-3 group text-sm" key={heading.value}>
                        <a href={'#' + slug(heading.value)} className="m-0 no-underline">
                            <LinkIcon className="w-4 h-4 m-0 text-green-500 inline-block" />
                            <span className="m-0 ml-2 text-gray-800 dark:text-white bg-gradient-to-r from-green-300 dark:from-green-800 bg-[length:0px_10px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_10px] group-hover:bg-[length:100%_10px]">{heading.value}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default TableOfContents;