import { Link } from "gatsby";
import * as React from "react";

export default function AuthorCard({ author }) {
  return (
    <div className="px-6 py-6 my-8 text-gray-500 rounded-lg bg-[#e5e5ef] dark:bg-neutral-800 dark:text-gray-400">
      <div className="flex flex-wrap items-start sm:space-x-6 sm:flex-nowrap">
        <div className="relative flex-shrink-0 w-24 h-24 mt-1 mb-4 md:mb-0">
          <img
            src="/preview.png"
            objectfit="cover"
            alt="Ivan Lesar"
            placeholder="blur"
            layout="fill"
            className="-scale-x-100 saturate-[0.7] contrast-[1.2] m-0"
          />
        </div>
        <div>
          <div className="mb-3">
            <h4 className="text-sm font-bold m-0 text-gray-800 dark:text-gray-300">
              About Ivan Lesar
            </h4>
          </div>
          <div className="text-sm">
            Curious overthinker with a purpose to tinker. Software engineer with a background in mathematics.
          </div>
          <div className="mt-4">
            <Link to="/about" className="text-sm text-blue-600 hover:underline">show more</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
