import ComponentDatabase from "#constants/ComponentDatabase"
import { cx } from "#utils/all"
import { incrementCounter } from "#utils/firebase"
import { useLocation } from "@gatsbyjs/reach-router"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import * as React from "react"

const ComponentList = ({ post, className }) => {
  const componentIds = post.frontmatter.components

  const location = useLocation()

  function incrementComponentClick(componentId) {
    incrementCounter(
      location,
      `posts${post.fields.slug}components/${componentId}`
    )
  }

  return (
    <>
      <aside
        className={cx(
          "md:sticky md:pt-2 md:top-8 md:opacity-100 hover:opacity-100 transition-opacity",
          className
        )}
      >
        <div className="font-sans">
          <div className="mt-0">
            {componentIds && (
              <>
                <h3 className="text-lg font-bold dark:text-white">
                  Components
                </h3>
                <div className="grid gap-3 mt-6 mb-6 md:mb-0 component-list-wrapper">
                  {componentIds.map(componentId => (
                    <a
                      href={ComponentDatabase[componentId].url}
                      key={ComponentDatabase[componentId].title}
                      data-component={componentId}
                      onClick={() => incrementComponentClick(componentId)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm group transition-all duration-200"
                    >
                      <div className="flex gap-3">
                        <div className="relative w-16 h-12 rounded-md shrink-0">
                          <img
                            src={ComponentDatabase[componentId].image}
                            alt="This Bread Pudding Will Give You All the Fall Feels"
                            className="group-hover:scale-125 transition-all overflow-hidden "
                            decoding="async"
                          />
                        </div>
                        <div>
                          <h3
                            className={`font-medium dark:text-white w-max bg-gradient-to-r from-blue-200 dark:from-blue-800 dark:to-gray-900
bg-[length:0px_10px]
bg-left-bottom
bg-no-repeat
transition-[background-size]
duration-500
hover:bg-[length:100%_3px] group-hover:bg-[length:100%_10px]`}
                          >
                            {ComponentDatabase[componentId].title}
                          </h3>
                          <p className="mt-1 text-xs text-gray-400 w-max ">
                            {ComponentDatabase[componentId].description}
                            &nbsp;
                            <ArrowTopRightOnSquareIcon className="w-3 h-3 mb-[1px] inline-block transition-all group-hover:opacity-100 group-hover:text-blue-600" />
                          </p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </aside>
    </>
  )
}

export default ComponentList
