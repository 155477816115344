import AuthorCard from "#components/blog/post/authorCard"
import ClapsButton from "#components/blog/post/clap-button"
import ComponentList from "#components/blog/post/component-list"
import ComponentCtaCard from "#components/blog/post/componentCtaCard"
import Header from "#components/blog/post/header"
import HeaderImage from "#components/blog/post/header-image"
import PostGallery from "#components/blog/post/post-gallery"
import PostNavigation from "#components/blog/post/post-navigation"
import ShareBottom from "#components/blog/post/share-bottom"
import ShareSidebar from "#components/blog/post/share-sidebar"
import TableOfContents from "#components/blog/post/table-of-contents"
import Container from "#components/global/container"
import Layout from "#components/global/layout"
import Seo from "#components/global/seo"
import { incrementCounter } from "#utils/firebase"
import { useLocation } from "@gatsbyjs/reach-router"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import { useEffect } from "react"

const BlogPostTemplate = ({ data }) => {
  const post = data.mdx
  const previous = data.previous
  const next = data.next
  const siteTitle = ``
  const headings = data.mdx.headings
  const components = post.frontmatter.components
  const gallery = post.frontmatter.gallery
    ? post.frontmatter.gallery.map(galleryImage => {
        return {
          src: galleryImage.image.childImageSharp.original.src,
          alt: galleryImage.alt,
        }
      })
    : []

  const location = useLocation()

  function incrementScroll() {
    incrementCounter(location, `posts${post.fields.slug}scroll`)
  }

  const getScrollHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )
  }

  const getIndicatorPercentageWidth = (currentPos, totalScroll) => {
    return Math.min(1.0, currentPos / totalScroll) * 100
  }

  const getCurrentPosition = () => {
    const currentPos = window.scrollY
    const { innerHeight } = window
    const scrollHeight = getScrollHeight()
    const footerHeight = 200
    const headerHeight = 0
    const scrollDistance =
      scrollHeight - innerHeight - footerHeight - headerHeight

    const indicatorWidth = getIndicatorPercentageWidth(
      currentPos,
      scrollDistance
    )

    return indicatorWidth
  }

  let startScroll = null

  const onScroll = () => {
    const indicatorWidth = getCurrentPosition()

    if (indicatorWidth > 90) {
      window.removeEventListener("scroll", onScroll)
      incrementScroll()
    }
  }

  setTimeout(() => {
    startScroll = getCurrentPosition()

    if (startScroll < 90) {
      window.addEventListener("scroll", onScroll)
    }
  }, 300)

  useEffect(() => {
    incrementCounter(location, `posts${post.fields.slug}views`)
  }, [])

  const postClasses = post.frontmatter.components ? "" : ""

  // useEffect(() => {
  //     /* - - - CONFIGURATION VARIABLES - - - */

  //     var __semio__params = {
  //         graphcommentId: "blog-localhost", // make sure the id is yours

  //         behaviour: {
  //             // HIGHLY RECOMMENDED
  //             uid: post.id, // uniq identifer for the comments thread on your page (ex: your page id)
  //         },

  //         // configure your variables here

  //     }

  //     /* - - - DON'T EDIT BELOW THIS LINE - - - */

  //     function __semio__onload() {
  //         // eslint-disable-next-line no-undef
  //         __semio__gc_graphlogin(__semio__params)
  //     }

  //     (function () {
  //         var gc = document.createElement('script'); gc.type = 'text/javascript'; gc.async = true;
  //         gc.onload = __semio__onload; gc.defer = true; gc.src = 'https://integration.graphcomment.com/gc_graphlogin.js?' + Date.now();
  //         (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
  //     })();
  // })

  return (
    <Layout siteTitle={{ siteTitle }}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.featuredImage.childImageSharp.original.src}
        publishDate={post.frontmatter.date.split("T")[0]}
        tags={post.frontmatter.tags
          .join(", ")
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .toLowerCase()}
      />
      <Container className="!pt-0">
        <Header post={post} />
      </Container>

      <HeaderImage post={post} />

      <Container>
        <div className="flex flex-col-reverse gap-0 md:gap-5 px-0 mx-auto md:flex-row mt-4 place-content-center">
          <ShareSidebar
            post={post}
            className="flex-1 self-start md:sticky md:top-8 flex-col hidden md:flex items-end"
          />
          <article
            className={`${postClasses} grow mx-auto md:mr-0 md:ml-0 prose prose-base dark:prose-invert prose-a:text-blue-500 text-[0.9rem] dark:text-stone-300 place-self-center`}
          >
            <TableOfContents headings={headings} />

            <MDXRenderer>{post.body}</MDXRenderer>
            {components && components.length > 0 && (
              <ComponentCtaCard></ComponentCtaCard>
            )}
            <PostGallery gallery={gallery} />

            <hr className="my-8 border-slate-300 list-none dark:border-slate-700"></hr>

            <ClapsButton
              slug={post.fields.slug}
              className="w-full mx-auto grid place-content-center rounded-none md:hidden"
            />
            <ShareBottom post={post} />
            <AuthorCard />

            {/* <div id="graphcomment"></div> */}

            <PostNavigation post={post} previous={previous} next={next} />
            <div className="flex justify-center mt-14 mb-7">
              <Link
                to="/"
                className="px-5 py-2 text-sm text-blue-600 rounded-full dark:text-blue-500 bg-brand-secondary/20 hover:underline"
              >
                View all posts
              </Link>
            </div>
          </article>
          <ComponentList post={post} className="self-start flex-1" />
        </div>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
        readingTime {
          text
        }
      }
      headings(depth: h3) {
        depth
        value
      }
      frontmatter {
        title
        date(formatString: "")
        description
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              webpOptions: { quality: 100 }
            )
            original {
              src
            }
          }
        }
        components
        gallery {
          alt
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
            resize {
              src
            }
          }
        }
        tags
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
            resize {
              src
            }
          }
        }
        tags
      }
    }
  }
`
