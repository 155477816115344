import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lightgallery.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import * as React from "react";
import { useEffect, useState } from 'react';

const PostGallery = ({ gallery }) => {

    const [columnClass, setColumnClass] = useState('grid-cols-2 md:grid-cols-3')

    useEffect(() => {
        if (!gallery) return;

        switch (gallery.length) {
            case 1:
                setColumnClass('grid-cols-1');
                break;
            case 2:
                setColumnClass('grid-cols-2');
                break;
            case 4:
                setColumnClass('grid-cols-2 md:grid-cols-4');
                break;
            case 8:
                setColumnClass('grid-cols-2 md:grid-cols-4');
                break;
            default:
                break;
        }
    }, [gallery]);

    return (
        <>
            {gallery && gallery.length > 0 && <h3 className='mb-4'>Gallery</h3>}
            {
                gallery && gallery.length > 0 && <LightGallery
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    elementClassNames={`grid gap-2 md:gap-3 ${columnClass}`}
                >
                    {gallery.map((galleryImage, index) => {
                        return (
                            <a className="inline-block aspect-square overflow-hidden" key={index} href={galleryImage.src}>
                                <img className="m-0 border border-gray-300 aspect-square object-cover dark:border-gray-700" alt={galleryImage.alt} src={galleryImage.src} />
                            </a>
                        )
                    })}
                </LightGallery>
            }
        </>
    );
}

export default PostGallery;